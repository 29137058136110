import { Link, Text } from '@chakra-ui/react'
import * as React from 'react'
import CookieConsent from 'react-cookie-consent'

const CookieBar = () => {
  return (
    // @ts-ignore
    <CookieConsent
      location="bottom"
      buttonText="Jeg forstår"
      cookieName="nas-cookieconsent"
      style={{ background: '#2B373B' }}
      buttonStyle={{
        color: '#630F27',
        fontSize: '16px',
        fontWeight: 'bold',
        backgroundColor: '#F7941D',
        borderRadius: '0.25rem',
      }}
      expires={150}
    >
      <Text lineHeight="short" color="white">
        Vi bruker analyseverktøy for å måle trafikken på våre nettsider, og informasjonskapsler som
        grunnlag for videreutvikling av våre tjenester og for å tilby relevant markedsføring. Om du
        velger å bruke samskipnaden.no blir anonymisert brukerdata samlet inn, men ingen
        personopplysninger om deg vil samles eller lagres.{' '}
        <Link href="/om-samskipnaden/cookies" textDecoration="underline">
          Vis meg mer.
        </Link>
      </Text>
    </CookieConsent>
  )
}

export default CookieBar
