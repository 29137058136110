import LocalizedStrings from 'react-localization'

interface StringMap {
  [key: string]: string
}

interface StringMaps {
  [key: string]: StringMap
}

const strings = {
  // The first item in this object is the default. I.e. Spanish (es) will be served this one.
  no: {
    wifiChangeDisclaimer:
      'Etter du har byttet passordet, anbefaler vi at du kobler fra og glemmer «Samskipnaden»-nettverket før du logger inn på nytt. Merk at det nye passordet må legges inn på nytt på alle dine enheter.',
    wirelessNetworks: 'Trådløst nettverk',
    wifi: 'Trådløst nettverk',
    noAvailiabeNetworks: 'Ingen tilgjengelige nettverk',
    generateNewPassword: 'Generer nytt passord',
    copied: 'Kopiert',
    copy: 'Kopier',
    yourNetworks: 'Dine nettverk',
    wifiText1:
      'Dette er ditt personlige passord til det trådløse nettet i din bolig. Navnet på nettverket du skal bruke er Samskipnaden.',
    wifiText2: 'Du kan fint dele passordet med dine gjester og besøkende.',
    wifiText3: 'Du kan også selv generere nytt passord i grensesnittet over.',
    wifiText4: 'Du finner mer informasjon om internett våre studentboliger her.',
    wifiText5:
      'Det er UiT som leverer og drifter internettet i våre boliger. De hjelper også med brukerstøtte og feilretting.',
    wifiText6: 'Her finner du nyttige brukerveiledninger om internett',
    wifiText7: 'Vi anbefaler å lese brukerveiledningene før du tar kontakt eller melder feil.',
    wifiText8: 'Her kan du kontakte eller melde feil til Orakelet',
    aboutEatery: 'Om spisestedet',
    aboutSamskipnaden: 'Om Samskipnaden',
    accept: 'Godkjenn',
    acceptConsent: 'Gi samtykke',
    accessTo: 'Her får du tilgang til',
    accountDeletion1: 'Sletting',
    phone: 'Telefon',
    studentCounsellingCentre: 'Studentrådgivingen',
    accountDeletion2:
      'Ønsker du å slette din konto? Dette kan ikke gjøres om. Vi gjør oppmerskom på at eksterne kontoer (som {0} og {1}) du muligens har knyttet opp mot Samskipnaden ikke vil bli slettet.',
    accountDeletionButton: 'Slett meg',
    accountDeletionConfirmDescription: 'Dette kan ikke angres!',
    accountDeletionConfirmTitle: 'Er du sikker?',
    activeApplications: 'Aktive søknader',
    addChild: 'Legg til barn',
    addFile: 'Legg til fil',
    addHousing: 'Legg til bolig',
    adding: 'Legger til...',
    addMoreChildren: 'Legg til flere barn',
    addPartner: 'Legg til samboer',
    address: 'Adresse',
    addressAfterVacating: 'Adresse etter utflytting',
    addToApplicationList: 'Legg til i søknadslisten',
    addToCalendar: ' Legg til i kalender',
    all: 'Alle',
    allDay: 'Hele dagen',
    allergens: 'Allergener',
    alreadyAdded: 'Lagt til',
    alreadyRegisteredUser: 'Allerede registrert bruker?',
    amount: 'Beløp',
    amountCredited: 'Kreditert beløp',
    amountDue: 'Skyldig beløp',
    analyticsAndCookies: 'Analytics og cookies',
    analyticsAndCookiesTermsText:
      'Vi forbedrer og utvikler tjenesten vår hele tiden, og til dette bruker vi ulike analyseverktøy og informasjonskapsler. Bruk av nødvendige analyseverktøy og informasjonskapsler ligger i vår',
    and: 'og',
    application: 'Søknad',
    applicationDate: 'Søknadsdato',
    applicationInformation: 'Søknadsinformasjon',
    applicationList: 'Søknadsliste',
    applicationListText:
      'Listen viser boligene du har valgt å søke på. Dra og slipp for å endre prioritet.',
    applicationPending: 'Søknad behandles',
    applicationRemoved: 'Søknaden slettet',
    applications: 'Søknader',
    applicationSentSuccessText:
      'Din søknad er mottatt! Vi har sendt en kvittering til din e-postadresse. Du kan følge søknaden din på Min Side.',
    applicationTitle: 'Søk studentbolig',
    applicationValidText:
      'Søknaden din er gyldig i {0} dager. Sørg for å fornye den innen fristen hvis du ikke har motatt et tilbud om bolig.',
    articles: 'Aktuelt',
    assigned: 'Tildelt',
    association: 'Tilhørighet',
    availability: 'Tilgjengelighet',
    availableHouses: 'Ledige boliger',
    back: 'Tilbake',
    backToEditApplication: 'Tilbake til søknad',
    bankAccountNumber: 'Kontonummer',
    bankAccountNumberInfo2: 'Må være din egen personlige konto',
    bankAccountTypeInfo: 'Internasjonale studenter uten Norsk bankkonto må bruke IBAN/SWIFT',
    basicInformation: 'Basisinformasjon',
    bath: 'Bad',
    bottomRentText: 'Du kan søke på flere boliger samtidig. Vi anbefaler minst 3.',
    byUsingLoginMethods: 'Ved å benytte én av påloggingsmetodene over, godtar du vår',
    calendar: 'Kalenderen',
    campus: 'Campus',
    cancel: 'Avbryt',
    cancelApplication: 'Slett søknad',
    cancelContractTermination: 'Bekreft tilbaketrekking av oppsigelse',
    cardOnly: 'Kun kort',
    category: 'Kategori',
    changeEmailHelperText: 'Ta kontakt med Samskipnaden hvis du vil endre e-postadresse',
    checklist: 'Sjekkliste',
    checklists: 'Sjekklister',
    children: 'Barn',
    childrenText: 'Viktig at barn fylles ut da dette har betydning for tildeling av familiebolig.',
    chooseBankAccountType: 'Velg type bankkonto',
    chooseCountry: 'Velg land',
    chooseMessageType: 'Velg type melding',
    choosePrice: 'Velg pris',
    chooseSize: 'Velg størrelse',
    city: 'By',
    cleaningStaff: 'Renhold',
    close: 'Lukk',
    codeFromSms: 'Kode fra SMS',
    comment: 'Kommentar',
    commentPlaceholder: 'Skriv en kommentar...',
    comments: 'Andre kommentarer',
    commentsText:
      'Her kan du fylle inn ekstrainformasjon som kan være relevant for søknaden. Har du venner du ønsker å bo ved siden av/dele leilighet med? Skriv det ned her!',
    commonAreaSelectLabel: 'Meld feil på',
    complete: 'Fullfør',
    completed: 'Fullført',
    confirm: 'Bekreft',
    confirmFireRoutineVideoCheckbox: 'Ja, jeg har sett videoen',
    confirmFireRoutineVideoText:
      'Hei! Som student er det viktig at du er godt kjent med våre rutiner i tilfelle brann.',
    confirmPhoneNumber: 'Bekreft mobilnummer',
    connect: 'Koble sammen',
    consent: 'Godkjenn tjeneste',
    consented: 'Samtykket',
    consents: 'Samtykker',
    consentText:
      'Samskipnaden samler mange av dine tjenester ett sted for å gjøre livet som student enklere. For å gjøre det mulig trenger vi ditt samtykke for deling av informasjon mellom Samskipnaden og dine andre tjenester.',
    contact: 'Kontakt',
    contactInfo: 'Kontaktinfo',
    contactInformation: 'Kontaktinformasjon',
    contactInformationText: 'Fyll inn kontaktinformasjon om deg',
    contactUs: 'Kontakt oss',
    continue: 'Gå videre',
    continueWithEmail: 'Fortsett med e-post',
    contract: 'Kontrakt',
    contractAcceptLockText: ' Jeg godtar at Samskipnaden kan låse seg inn i boligen',
    contractExpiresAt: 'Kontrakten din utløper',
    contractFirstPossibleVacatingDate: 'Ved oppsigelse vil kontrakten gå ut',
    contractId: 'Kontrakt-ID',
    contracts: 'Kontrakter',
    contractSettlementInfo: 'Informasjon on tilbakebetaling',
    contractTerminationText: 'Registrer oppsiglese av leiekontrakt for',
    contractTerminationTitle: 'Register oppsigelse',
    cookies: 'informasjonskapsler',
    country: 'Land',
    create: 'Opprett',
    createEvent: 'Opprett arrangement',
    createStudentUnion: 'Legg til studentforening',
    createNewMessage: 'Opprett ny henvendelse',
    createUser: 'Opprett bruker',
    daily: 'Daglig',
    date: 'Dato',
    dateOfBirth: 'Fødselsdato',
    deadline: 'Frist for utfylling',
    declineContract: 'Avslå kontrakt',
    deletingApplication: 'Sletter søknaden...',
    deposit: 'Depositum',
    depositPayButton: 'Betal depositum',
    description: 'Beskrivelse',
    descriptionPlaceholder: 'Hva gjelder henvendelsen?',
    details: 'Detaljer',
    documentation: 'Dokumentasjon',
    documentationText:
      ' Dersom du er førsteårsstudent og enda ikke har fått dokumentasjon på at du er kommet inn på studiet, kan du hoppe over punktet om dokumentasjon. Alle andre kan legge ved dokumentasjon på betalt studieavgift, studieprogresjon eller lignende.',
    download: 'Last ned',
    downloadInvoice: 'Last ned faktura',
    dueDate: 'Forfall',
    editApplication: 'Rediger søknad',
    editPeriod: 'Boligperiode',
    editPeriodText: 'Fyll inn når du ønsker bolig fra og til.',
    email: 'E-postadresse',
    errorGymAccountDetails:
      'Vi kunne dessverre ikke koble kontodetaljene dine til et Kraftmedlemskap. Logg på Feide eller verifiser at du benytter samme mobilnummer på MinSide som på Kraft Sportssenter.',
    errorLogin: 'En feil oppstod ved innloggingen!',
    errorRefreshPage: 'Noe gikk galt. Forsøk å laste inn siden på nytt.',
    errorSomethingWrong: 'Noe gikk galt',
    event: 'arrangement',
    events: 'arrangementer',
    expired: 'Utløpt',
    expiredApplications: 'Utgåtte søknader',
    expiredDate: 'Utløpsdato',
    expiredMemberships: 'Utgått medlemskap',
    facilities: 'Fasiliteter',
    fault: 'Avvik',
    faculty: 'Fakultet',
    fetchingPlaces: 'Henter steder...',
    filesWithErrors: 'Filer med feil',
    findHousing: 'Finn bolig',
    firstName: 'Fornavn',
    FoodAndBeverageConsentPermissions1: 'Lagre informasjon om hvert kjøp registrert med QR kode',
    FoodAndBeverageConsentPermissions2: 'Opprette konto i MUNU brukerdatabase',
    FoodAndBeverageConsentPermissions3:
      'Opprettelse av QR kode og deltagelse i Samskipnadens fordelsprogram',
    FoodAndBeverageConsentPermissions4:
      'Bruk av kjøps-, fordels- og aktivitetsinformasjon til videreutvikling av tjeneste og tilrettelagte tilbud',
    FoodAndBeverageConsentText:
      'Samskipnaden samler mange av dine tjenester ett sted for å forenkle bruken av våre tjenester. For å skape bedre tjenester samler vi inn og analyserer vi informasjon om bruk, kjøp, kupongbruk. Vi benytter også innsamlet data for å gi tilrettelagte tilbud. Ved bruk av vårt fordelsprogram opprettes en anonymisert konto i vårt kassasystem MUNU.',
    FoodAndBeverageConsentTitle: 'Samtykke til:',
    forgotPassword: 'Glemt passord?',
    formErrors: ' Skjema er ikke riktig utfylt. Se individuelle feilmeldinger i skjema',
    foundNoEvents: 'Fant ingen arrangementer',
    foundNoNews: 'Fant ingen nyheter',
    freeCoffee: 'Du har fått en gratis kaffe!',
    freeCoffeeDescription:
      'Scan din QR kode i kassen neste gang du handler i en av våre spisesteder, og du vil få en gratis kaffe. Videre får du hver tiende kaffe gratis når du skanner din QR kode.',
    friday: 'Fredag',
    fromDate: 'Fra dato',
    fromSemester: 'Fra semester',
    frontPage: 'Forside',
    fullName: 'Fullt navn',
    genderExplainer: 'Hvorfor trenger vi dette? Kort forklaring kommer her.',
    generalError: 'Noe gikk galt. Vennligst prøv igjen senere.',
    generalErrorMessage: 'Noe gikk galt. Prøv igjen senere.',
    generalLoginErrorMessage: 'Feil brukernavn eller passord',
    generalTermsOfUse: 'generelle vilkår for bruk.',
    getAccess: 'Få tilgang',
    getAccessLoading: 'Gir tilgang...',
    getStarted: 'Kom i gang',
    giveConcent: 'Gi samtykke',
    good: 'Bra',
    goToApplication: 'Gå til søknadskjema',
    goToLegacyContract: 'Gå til boligportal',
    goToMyPage: 'Gå til Min Side',
    goToNext: 'Gå til neste',
    goToPasswordReset: 'Tilbakestill passord',
    gymConsentPermissions1: 'Hente din kontoinformasjon fra Kraft og iBooking',
    gymConsentPermissions2: 'Lagre og presentere besøk, betaling og medlemsskapsinformasjon',
    gymConsentPermissions3:
      'Analysere treningsdata fra Kraft og iBooking for utvikling av bedre tjenester og tilrettelagte tilbud på Samskipnaden.no',
    gymConsentText:
      'Samskipnaden samler mange av dine tjenester ett sted for å forenkle bruken av våre tjenester. Ved å gi samtykke til bruk av «Min trening» gir du tillatelse for å hente inn din medlemsskapsinformasjon fra Kraft Sportssenter og iBooking. Vi benytter innsamlet data for å presentere din medlemskapsinfo på MinSide, gi tilrettelagte tilbud og forbedrede våre tjenester.',
    gymConsentTitle: 'Samtykke til:',
    handicap: 'Jeg ønsker bolig tilpasset person med nedsatt funksjonsevne',
    handicapAdapted: 'Tilrettelagt',
    handicapText: 'Beskriv dine behov her. NB: Ikke oppgi medisisk informasjon.',
    health: 'Helse',
    healthConsentText: '',
    healthConsentTitle: 'Samtykke til:',
    helpMe: 'Hjelp meg',
    hi: 'Hei',
    home: 'Hjem',
    housing: 'Bolig',
    housingApplicationConsentText:
      'Jeg samtykker at alle opplysninger er korrekt og til lagring av mine personlige opplysninger slik at Samskipnaden kan behandle min søknad.',
    housingArea: 'Boligområde',
    housingConsentPermissions1:
      'Henting, visning og lagring av boliginformasjonen som den innloggede bruker har registrert på UniAlltid, Samskipnadens tidligere boligportal (studentbolig.samskipnaden.no), og/eller på MinSide. Boliginformasjonen inkluderer og er ikke begrenset til fakturainformasjon, boforhold, sjekklister, søknader, informasjon om barn og samboer, studiestatus og lignende. Brukeren gir også Samskipnaden retten til å vise og sende henvendelser (med og uten vedlegg) til boligtjenesten levert av Ørn Software AS, på MinSide.',
    housingConsentPermissions2: '----',
    housingConsentPermissions3: '----',
    housingConsentText:
      'Henting, visning og lagring av boliginformasjonen som den innloggede bruker har registrert på UniAlltid, Samskipnadens tidligere boligportal (studentbolig.samskipnaden.no), og/eller på MinSide. Boliginformasjonen inkluderer og er ikke begrenset til fakturainformasjon, boforhold, sjekklister, søknader, informasjon om barn og samboer, studiestatus og lignende. Brukeren gir også Samskipnaden retten til å vise og sende henvendelser (med og uten vedlegg) til boligtjenesten levert av Ørn Software AS, på MinSide.',
    housingConsentTitle: 'Samtykke til:',
    housingLoginIntroText1:
      'Vi har slått sammen den gamle boligportalen med Min Side. For å administrere ditt boligforhold må du logge inn på Samskipnaden Min Side',
    housingLoginIntroText2a: 'Trykk her for å se et bilde',
    housingLoginIntroText2b:
      ' av Min Side og den gamle boligportalen hvis du er usikker på om du har vært logget inn før.',
    housingLoginPageText:
      'Vi har slått sammen gammel boligportal med Samskipnaden Min Side. Du må først opprette eller logge inn på Min Side for å få tilgang til boligsidene.',
    housingLoginPageTitle: 'Viktig info om Samskipnadens boligsider',
    housingLoginSignup: 'Hvis du ikke har en bruker kan du opprette en her.',
    housingLoginSignupQ1: 'Hvorfor må jeg gjøre dette?',
    housingLoginSignupQ1Answer:
      ' Ved å opprette og logge deg inn knytter du sammen Min side og Samskipnadens boligsider. Det er to separate systemer som må kobles sammen for at du skal kunne benytte deg av våre boligtjenester via Min Side.',
    housingLoginSignupQ2: 'For nåværende/tidligere beboere og tidligere søkere',
    housingLoginSignupQ2Answer:
      'Dersom du bor hos oss eller har søkt tidligere, har du sannsynligvis allerede en bruker på våre boligsider. Du trenger dermed ikke å lage en ny bruker, men kan prøve å logge deg inn med påloggingsdetaljer fra vår gamle boligportal under.',
    housingLoginText:
      'Vi har slått sammen gammel boligportal med Samskipnaden Min Side. Du må logge inn med din gamle boligbruker for å få tilgang.',
    housingLoginTitle: 'Viktig info om Samskipnadens boligsider',
    housingMoveOutBookingButtonLabel: 'Gå til bestillingsside',
    housingMoveOutBookingText:
      'Vi utvider vårt tilbud for å gjøre utflyttingsprosessen bedre for deg! Bestill en utflyttingssjekk før utflytting. Vi går gjennom boligen sammen med deg, dersom vaskingen er godkjent kan du levere nøkkelen med en gang!',
    housingMoveOutBookingTitle: 'Bestill utflyttingssjekk',
    housingNameFormTitle: 'Vi trenger også navnet ditt for å koble systemene sammen.',
    housingPreferredRelocationHelperText:
      'Legg gjerne inn når du planlegger å flytte. Dette er ikke bindende og endrer ikke utløpet av kontrakten eller forpliktelsen til å betale husleie hele oppsigelestiden.',
    housingRegisterUserConsent: 'Ved å gå videre godtar du vår',
    housingShowHouses: 'Vis boliger',
    housingSignupLogin: 'Hvis du allerede bor hos oss kan du logge inn her.',
    housingSignupText:
      'Vi har slått sammen gammel boligportal med Samskipnaden Min Side. Du må registrere en boligbruker for å få tilgang.',
    housingSignupTitle: 'Viktig info om Samskipnadens boligsider',
    housingTypes: 'boligtyper',
    housingWishes: 'Boligønsker',
    housingWishLimitWarning:
      'Du kan maks ha {0} boliger i søknadslisten. Du må fjerne minst {1} boliger før du kan søke.',
    ibanSwift: 'Bank med IBAN/SWIFT',
    inbox: 'Innboks',
    invalid: 'Ugyldig',
    invoice: 'Faktura',
    invoiceDate: 'Fakturadato',
    invoiceNumber: 'Fakturanummer',
    invoicePayButton: 'Betal faktura',
    invoicePayButtonLoading: 'Sender deg til betalingsside',
    invoicePaymentAbortedDescription: 'Betaling ble avbrutt.',
    invoicePaymentAbortedTitle: 'Betaling avbrutt',
    invoicePaymentErrorDescription: 'Det oppstod en feil ved betaling. Vennligst prøv igjen.',
    invoicePaymentErrorTitle: 'En feil oppstod',
    invoicePaymentSuccessDescription:
      'Betalingen er nå registrert og du vil motta en kvittering på e-post når den er behandlet av bank.',
    invoicePaymentSuccessTitle: 'Faktura betaling',
    invoices: 'Faktura',
    keyInfo: 'Nøkkelinformasjon',
    kinderGartenHeadline: 'Visste du at vi har barnehager?',
    kinderGartenText: 'Er du student med barn, er det mange fordeler ved å velge studentbarnehage.',
    kitchen: 'Kjøkken',
    label: 'Etikett',
    language: 'Språk',
    lastName: 'Etternavn',
    laundry: 'Vaskeri',
    legacyPortalBannerLink: ' Bytt til gammel her for mer innhold.',
    legacyPortalBannerText: 'Vår nye boligportal er i beta.',
    livingPeriod: 'Boligperiode',
    livingPeriodText: 'Fyll inn når du ønsker bolig fra og til.',
    loading: 'Laster...',
    loggingIn: 'Logger inn',
    login: 'Logg inn',
    logIn: 'Logg inn',
    logInAndReceivePerks1: 'Logg inn og få fordeler',
    logInAndReceivePerks2:
      'Som medlem får du tilgang til tilbud, klippekort og ditt medlemsbevis helt gratis.',
    logInWithFeide: 'Logg inn med Feide',
    logInWithGoogle: 'Logg inn med Google',
    logout: 'Logg ut',
    loyaltyCardCoffee:
      'Tilbudet gjelder svart kaffe i våre kantiner, og registreres automatisk når du skanner koden din i kassen. Tilbudet gjelder ikke kaffe på GRUT.',
    loyaltyCardGRUTkaffekort:
      'Tilbudet gjelder enkel eller dobbel kaffe (latte, frappe o.l.) og ikke dagens eller vanlig kaffe/te. Kjøpet registreres automatisk når du skanner koden din i kassen. Tilbudet gjelder ikke andre utsalgsteder enn GRUT kaffebarer.',
    loyaltyCardGRUTkaffekortdagens:
      'Tilbudet gjelder kjøp av dagens kaffe/te, ikke espressokaffe. Kjøpet registreres automatisk når du skanner koden din i kassen. Tilbudet gjelder ikke andre utsalgsteder enn GRUT kaffebarer.',
    loyaltyCardKaffekort:
      'Tilbudet gjelder svart kaffe i våre kantiner, og registreres automatisk når du skanner koden din i kassen. Tilbudet gjelder ikke kaffe på GRUT.',
    man: 'Mann',
    marketingConsentText: 'Motta tilbud og nyheter vedrørende Samskipnadens tjenester på e-post',
    marketingConsentTitle: 'Samtykke til:',
    maxApplicationsWarning: 'Du kan maks ha {0} søknader inne av gangen',
    memberships: 'Medlemskap',
    menu: 'Meny',
    message: 'Henvendelse',
    messageAcceptLockText:
      'Jeg samtykker til at driftspersonell kan låse seg inn i boligen og fikse feil selv om jeg ikke er til stede.',
    messageAcceptLockTextCont:
      'Vårt driftspersonell vil alltid prøve å komme i kontakt med deg før feilretting utføres. Ved samtykke vil feil kunnes rettes raskere. Feilretting utføres normalt innenfor ordinær arbeidstid.',
    messages: 'Henvendelser',
    messageType: 'Type henvendelse',
    mobilePhoneNumber: 'Mobilnummer',
    monday: 'Mandag',
    month: 'Måned',
    monthly: 'Månedlig',
    moreFromArticles: 'Aktuelt fra Samskipnaden',
    moreFromHousing: 'Mer fra bolig',
    moreFromUnions: 'Flere studentforeninger',
    moreInfoFeide: 'Mer informasjon om Feide',
    morePlacesToStay: 'Flere steder å bo',
    moveInDate: 'Innflyttingsdato',
    mustAccept: 'Du må akseptere vilkårene',
    mustBeAfterDate: 'Til dato må være etter fra dato',
    mustBeAfterSemester: 'Semester start må være før slutt',
    mustBeFuture: 'Dato må være fram i tid',
    mustBeNumber: 'Må være et nummer',
    myConsents: 'Samtykker',
    myConsentsText: 'Se og endre mine samtykker',
    myConsentsUrl: '/minside/samtykker',
    myFavourites: 'Favoritter',
    myFavouritesText: 'Ditt campus og favoritt spisesteder',
    myFavouritesUrl: '/minside/favoritter',
    myGym: 'Min trening',
    myGymText: 'Mitt medlemskap, betalinger, og antall besøk',
    myGymUrl: '/minside/trening',
    myHealthUrl: '/minside/helse',
    myHomeUrl: '/minside',
    myHousing: 'Bolig',
    myHousingUrl: '/minside/bolig',
    myInboxUrl: '/minside/innboks',
    myPage: 'Min side',
    myPageForYou: 'Tilbud for deg',
    myPageHousingApplyLink: 'Finn studentbolig',
    myPageHousingApplyText: 'For å bruke denne tjenesten, må du registrere deg som boligsøker.',
    myPageHousingApplyTitle: 'Søk studentbolig',
    myPageHousingForgotPasswordItem1: 'Trykk «Tilbakestill passord» under.',
    myPageHousingForgotPasswordItem2A:
      'I det nye vinduet, skriv inn den e-posten du har brukt i den gamle boligportalen',
    myPageHousingForgotPasswordItem2B: ' og trykk «tilbakestill».',
    myPageHousingForgotPasswordItem3:
      'Du vil få en e-post med lenke. Klikk på lenken og lag nytt passord.',
    myPageHousingForgotPasswordItem4: 'Kom tilbake hit og skriv inn det nye passordet.',
    myPageHousingForgotPasswordTitle:
      'Vi beklager ulempen, passordet må tilbakestilles i den gamle boligportalen.',
    myPageHousingGetAccessText:
      'Kunne bo i en av Samskipandens flotte boliger. Alle kontrakter, fakturaer, inn og utflyttingslister på et sted. Sende melding til vaktmester hvis det er noe feil eller mangler',
    myPageHousingGetAccessTitle: 'Alt om din studentbolig på ett sted',
    myPageHousingGetStartedText: 'Har du søkt hos eller bodd hos oss før?',
    myPageHousingGetStartedTitle:
      'Vi har slått sammen våre boligsystemer og trenger mer info fra deg',
    myPageHousingListItem1: 'Søk bolig og administrer din søknad',
    myPageHousingListItem2: 'Tilgang på alle kontrakter, fakturaer og meldinger',
    myPageHousingListItem3: 'Kontakt Samskipnadens drift- og boligpersonale',
    myPageHousingLoginHelperText:
      'NB! Du kan ha brukt en annen e-postadresse i den gamle boligportalen.',
    myPageHousingLoginTitle: 'Logg inn med e-post og passord fra den gamle boligportalen.',
    myPageHousingUserExistsButton: 'Gå til pålogging',
    myPageHousingUserExistsText1A:
      'Vi har slått sammen den gamle boligportalen med Min side. Din e-post ',
    myPageHousingUserExistsText1B: ' finnes allerede i den gamle boligportalen.',
    myPageHousingUserExistsText2:
      'Du må logge inn med din eksisterende bruker for å få tilgang til boligtjenestene på Min side.',
    myPageHousingUserExistsText3:
      'Hvis du ikke helt vet hva vi snakker om, trykk her for å kontakte oss slik at vi kan hjelpe deg',
    myPageHousingUserExistsTitle: 'Din e-post er allerede registrert!',
    myPerks: 'Mine fordeler',
    myPerksText: 'Mine fordeler og klippekort',
    myPerksUrl: '/minside/fordeler',
    myPrepaid: 'Mine klippekort',
    myPrepaidText: 'Se og kjøp klippekort',
    myPrepaidUrl: '/minside/klippekort',
    myProfile: 'Min profil',
    myProfileText: 'Endre kontakt info og tilhørighet',
    myProfileUrl: '/minside/profil',
    myPurchases: 'Kjøp',
    myPurchasesText: 'Mine lojalitetskjøp og rabatter',
    myPurchasesUrl: '/minside/kjop',
    newMessage: 'Ny henvendelse',
    news: 'Nyheter',
    newUser: 'Ny bruker',
    nextOfKin: 'pårørende',
    nextOfKinText: 'Fyll inn kontaktinformasjon for din pårørende.',
    no: 'Nei',
    noApplications: 'Ingen aktive søknader',
    noApplicationsText: 'Trenger du et sted å bo? Vi har mange ledige boliger!',
    noComments: 'Ingen kommentarer',
    noContracts: 'Ingen kontrakter',
    noFavourites1: 'Ingen favoritter',
    noFavourites2: 'Vi fant ingen lagrede favoritter.',
    noHits: 'Ingen treff',
    noHousesWarning: ' Du har ingen boliger i din søknadsliste.',
    noLoyaltyCard: 'Ingen klippekort',
    noMembership: 'Ingen medlemskap',
    none: 'Ingen',
    nonStudent: 'Ikke student',
    noPurchases: 'Ingen kjøp',
    norwegianBankAccount: 'Norsk bankkonto',
    notFound: 'Finner ikke side',
    notFoundDescription:
      'Her gikk noe galt. Det kan være en feil med URL-en, at vi gjør vedlikehold, eller at siden rett og slett ikke finnes.',
    notFoundGoHome: 'Gå til forsiden',
    notifications: 'Varsler',
    notNow: 'Ikke nå',
    notRegisteredUser: 'Ikke registrert bruker?',
    notRelevant: 'Ikke relevant',
    notValidEmail: 'Ikke gyldig e-postadresse',
    noUnpaidInvoice: 'Ingen ubetalte faktura',
    numberOfKeys: 'Antall nøkler',
    numberOfRooms: 'Antall rom',
    of: 'av',
    oftenUsedPages: 'Ofte brukte sider',
    onboardingCodeError:
      'Vi kunne dessverre ikke sende verifiseringskoden. Vennligst sjekk at telefonnummeret er korrekt.',
    onboardingConsentAlert:
      'Hvis vi ikke får ditt samtykke vil vi kun opprette en konto på MinSide, men uten tilgang til noen av våre tjenester eller innholdet på MinSide. Du kan alltids slette din MinSide konto under Profil.',
    onboardingConsentIntro:
      'Vi trenger ditt samtykke for å kunne gi deg tilganger til våre tjenester, og ikke minst de beste fordelene og tilbudene på MinSide! ',
    onboardingConsentLoyaltyProgram: 'Ja, jeg ønsker å bli med i Samskipnadens fordelsprogram',
    onboardingConsentMarketing:
      'Ja, jeg vil også motta tilbud og nyheter fra Samskipnaden på e-post.',
    onboardingConsentReadPrivacy: 'Les mer om personvern og samtykke.',
    onboardingConsentText:
      'For å skape bedre tjenester samler vi inn og analyserer informasjon om bruk, kjøp og kupongbruk. Vi bruker også innsamlet data for å tilrettelegge tilbud for deg. Ved bruk av vårt fordelsprogram opprettes en anonymisert konto i vårt kassasystem.',
    onboardingConsentTraining:
      'Ja, jeg ønsker å knytte min Kraft Sportssenter konto til Samskipnadens MinSide',
    onboardingGoBack: 'Gå tilbake',
    onboardingNotRecievedCode: 'Ikke mottatt kode?',
    onboardingTellUsAbout: 'Fortell oss litt om deg selv',
    onboardingWelcome: 'Velkommen!',
    onboardingWelcomeIntro:
      'På MinSide finner du QR-koden til alle våre kantiner og kaffebarer og dine tilbud som fordelsmedlem. Innhold på Min side:',
    onboardingWelcomeText1: 'Få eksklusive fordeler og gode tilbud til deg som medlem på Min side',
    onboardingWelcomeText2: 'Få oversikt over kjøp og transaksjoner i våre kantiner og kaffebarer',
    onboardingWelcomeText3: 'Kjøp studentklippekort for bedre priser i våre kantiner og kaffebarer',
    onboardingWelcomeText4: 'Administrer din studentbolig og treningsmedlemskap på ett sted',
    onboardingWelcomeText5: 'Bruk QR koden din i alle våre kantiner og kaffebarer',
    onboardingWelcomeText6:
      'Med konto på MinSide får tilgang til alle Samskipnadens tjenester, på ett sted. Tilknytt din Feidekonto, med egen pålogging, og få studentpriser med en gang.',
    openQrCode: 'Vis min QR-kode',
    or: 'eller',
    other: 'Andre henvendelser',
    otherInformation: 'Annen informasjon',
    ourServices: 'Våre tjenester',
    own: 'Eget',
    paid: 'Betalt',
    paidOut: 'Tilbakebetalt',
    partner: 'Samboer',
    partnerText: 'Legg til dersom du ønsker å dele leilighet med en samboer.',
    password: 'Passord',
    pastApplications: 'Tidligere søknader',
    pastContracts: 'Tidligere kontrakter',
    payments: 'Betalinger',
    perks: 'Fordeler',
    perMonth: 'pr/mnd',
    pets: 'Dyrehold',
    petsText:
      ' Dyrehold er kun tillatt i enkelte leiligheter. Leietakere må sende egen skriftlig søknad til Utleier om tillatelse til Dyrehold. Søknaden må oppgi hvilken type dyr den gjelder og være begrunnet.',
    pick: 'Velg',
    place: 'Sted',
    placeOfStudy: 'Studiested',
    plannedVacatingDate: 'Planlagt dato for utflytting',
    pleaseWait: 'Vent litt...',
    plural: 'er',
    postalCode: 'Postnummer',
    preferences: 'Preferanser',
    prepaid: 'Klippekort',
    price: 'Pris',
    privacy: 'Personvern',
    roomNumber: 'Romnummer',
    privacyPolicy: 'personvernerklæring',
    problem: 'Feilmelding',
    profile: 'Profil',
    profileHousingAccountDescription: 'Brukes til tilbakebetaling av depositum, leie o.l.',
    qrCodeText: 'Skann denne koden på alle spisesteder for å automatisk få best pris og fordeler.',
    quickAdd: 'Legg til',
    readMoreAboutGymOffer: 'Les mer om våre treningstilbud.',
    readMoreConsent: 'Les mer om samtykke her.',
    readMorePrivacy: 'Les om personvern her.',
    recommendedForStudents: 'Anbefales hvis du er student!',
    remove: 'Fjern',
    removeApplicationText:
      'Er du sikker på at du vil trekke søknaden din? Dette kan ikke omgjøres og du må søke på nytt',
    renewApplication: 'Forny søknad',
    rent: 'Leie',
    rentIncludes: 'Inkludert i husleien',
    requestRoomSwapText: 'Du kan sende en forespørsel om å bytte rom på denne kontrakten.',
    requestRwapRoomTitle: 'Forespørsel om rombytte',
    required: 'Påkrevd',
    reset: 'Nullstill',
    room: 'Rom',
    roomSwap: 'Forespørsel om rombytte',
    roomSwapAsap: 'Jeg vil bytte rom snarest',
    roomSwapSubmit: 'Send forespørsel',
    roomSwapText: 'Forespørsel om rombytte for',
    roomSwapTitle: 'Forespørsel om rombytte',
    saturday: 'Lørdag',
    save: 'Lagre',
    saveAndClose: 'Lagre og lukk',
    saveAndNext: 'Lagre og gå til neste',
    saveBankAccount: 'Lagre kontonummer',
    savePreferences: 'Lagre preferanser',
    saveProfile: 'Lagre profil',
    saving: 'Lagrer...',
    search: 'Søk',
    searchTitle: 'Hva leter du etter?',
    seeAllArticles: 'Se alt fra aktuelt',
    seeAllKindergartens: 'Se alle barnehager',
    seeAllNews: 'Se alle nyheter',
    seeAllUnions: 'Se alle',
    seeMoreHouses: 'Se flere boliger',
    seeMoreServices: 'Se flere av våre tilbud og tjenester',
    seeMyPerks: 'Se mine fordeler',
    selectGender: 'Velg kjønn',
    sendApplication: 'Send søknad',
    service: 'Tjeneste',
    shared: 'Delt',
    shortTermRental: 'Korttidsleie',
    show: 'Vis',
    showApplicationList: 'Vis søknadsliste',
    showingArticleNum: 'Viser {0} av {1} artikler',
    showingEventsNum: 'Viser {0} av {1} arrangementer',
    showingSearchResultsNum: 'Viser {0} treff for {1}',
    showingStudentUnionNum: 'Viser {0} av {1} studentforeninger',
    showLess: 'Vis mindre',
    showMore: 'Vis mer',
    showOlder: 'Vis eldre',
    signContractAbortedDescription:
      'Signering av kontrakt ble avbrutt. Du kan fortsatt signere kontrakten frem til svarfristen, som du kan finne under kontraktsdetaljer.',
    signContractAbortedTitle: 'Signering avbrutt',
    signContractDigitallyButton: 'Signer med BankID',
    signContractErrorDescription:
      'Det oppstod en feil ved signering av kontrakten. Vennligst prøv igjen.',
    signContractErrorTitle: 'Signering feilet',
    signContractSuccessDescription: 'Signering fullført, takk for din signatur.',
    signContractSuccessTitle: 'Kontrakten er signert',
    signContractText:
      'Du kan signere kontrakt med BankID eller laste opp en signert versjon av kontrakten.',
    signContractTitle: 'Signering av kontrakt',
    signedContract: 'Signert kontrakt',
    size: 'Størrelse',
    special: 'Dagens',
    specialNeeds: 'Spesielle behov',
    specialNeedsText:
      'For å bli prioritert for bolig tilpasset personer nedsatt funksjonsevne, oppgi dette her. (Tekst om dokumentasjon må inn her.)',
    startChecklist: 'Start utfylling',
    startRoomSwap: 'Start forespørsel',
    startTerminateContract: 'Start oppsigelse',
    startUsingMyPage: 'Gå til MinSide',
    status: 'Status',
    storageRoom: 'Bod (Tromsø)',
    studentHouse: 'Studenthus',
    studentHousing: 'Studentboliger',
    studyInformation: 'Studieinformasjon',
    studyPeriod: 'Studieperiode',
    submitTermination: 'Send oppsigelse',
    submitting: 'Sender inn...',
    suitableFor: 'Passer for',
    summary: 'Oppsummering',
    summaryText: 'Sjekk at alt er riktig utfylt.',
    sunday: 'Søndag',
    tasks: 'oppgaver',
    tenancy: 'Leieforhold',
    terminateContractText: 'Du kan si opp din kontrakt iht. Samskipnadens vilkår.',
    terminateContractTitle: 'Oppsigelse',
    termination: 'Oppsigelse',
    thisWeek: 'Denne uken',
    thursday: 'Torsdag',
    title: 'Tittel',
    titlePlaceholder: 'Skriv inn tittel',
    toDate: 'Til dato',
    tooHighNumber: 'Tallet er for høyt',
    tooLowNumber: 'Tallet er for lavt',
    topic: 'Tema',
    toSemester: 'Til semester',
    training: 'Trening',
    trainingPartner: 'Treningspartner',
    tuesday: 'Tirsdag',
    unions: 'Studentforeninger',
    unknownStatus: 'Ukjent status',
    unmanned: 'Ubetjent',
    unpaid: 'Ubetalt',
    unSignedContract: 'Usignert kontrakt',
    updated: 'Oppdatert',
    uploadSignedContractText: 'Last opp signert kontrakt',
    usefulLinks: 'Nyttige lenker',
    validationBankAccount: 'Må være 11 siffer uten mellomrom og punktum',
    verifyAlertDescription1:
      'Verifiser deg som student for å få best priser, tilbud og tilgang til alle tjenester.',
    verifyAlertDescription2:
      'Verifiseringen gjøres enkelt ved at du logger deg inn med din Feide-konto',
    verifyAlertTitle: 'Er du student?',
    viewApplication: 'Se søknad',
    viewList: 'Vis liste',
    viewMap: 'Vis i kart',
    visits: 'Besøk',
    website: 'Nettsted',
    wednesday: 'Onsdag',
    weekly: 'Ukentlig',
    weFoundNoLoyaltyCard: 'Vi fant ingen aktive klippekort.',
    weFoundNoPurchases: 'Vi fant ingen søk',
    welcome: 'Velkommen',
    withdrawContractTerminationButton: 'Angre oppsigelse',
    withdrawContractTerminationText: 'Du kan fortsatt trekke tilbake din oppsigelse.',
    withdrawContractTerminationTitle: 'Trekk oppsigelse',
    woman: 'Kvinne',
    writeMessageHere: 'Skriv din melding her',
    yearly: 'Årlig',
    yearOfBirth: 'Fødselsår',
    yes: 'Ja',
    yourCode: 'Din kode',
    yourEmail: 'Din e-postadresse',
    yourFirstName: 'Ditt fornavn',
    yourLastName: 'Ditt etternavn',
    yourName: 'Ditt navn',
    yourPhoneNumber: 'Mobilnummer',
  },
  en: {
    aboutEatery: 'About eatery',
    aboutSamskipnaden: 'About Samskipnaden',
    accept: 'Accept',
    acceptConsent: 'Consent',
    accessTo: 'Get access to',
    accountDeletion1: 'Deleting',
    accountDeletion2:
      'Do you want to delete your account? This cannot be undone. Note that external accounts (like {0} and {1}) you might have connected to Samskpnaden will not be deleted.',
    accountDeletionButton: 'Delete me',
    accountDeletionConfirmDescription: 'This cannot be undone!',
    accountDeletionConfirmTitle: 'Are you sure?',
    activeApplications: 'Active applications',
    addChild: 'Add child',
    addFile: 'Add file',
    addHousing: 'Add housing',
    adding: 'Adding...',
    addMoreChildren: 'Add more children',
    addPartner: 'Add partner',
    address: 'Address',
    addressAfterVacating: 'Address after vacating',
    addToApplication: 'Edit application',
    addToApplicationList: 'Add to application',
    addToCalendar: 'Add to calendar',
    all: 'All',
    allDay: 'All day',
    allergens: 'Allergens',
    alreadyAdded: 'Already added',
    alreadyRegisteredUser: 'Already a registered user?',
    amount: 'Amount',
    amountCredited: 'Credited amount',
    amountDue: 'Amount due',
    analyticsAndCookies: 'Analytics and cookies',
    analyticsAndCookiesTermsText:
      'We improve and develop our service continuously, and for this we use different analytical tools and cookies. Usage of necessary analytical tools and cookies is described in our',
    and: 'and',
    application: 'Application',
    applicationDate: 'Application date',
    applicationInformation: 'Application information',
    applicationList: 'Application list',
    applicationListText:
      'The list shows all homes you want to apply for. Prioritise by drag-and-drop the homes in the order you want to apply for them.',
    applicationPending: 'Application pending',
    applicationRemoved: 'Application deleted',
    applications: 'Applications',
    applicationSentSuccessText: 'Application sent successfully',
    applicationTitle: 'Apply for student housing',
    applicationValidText:
      'Your application is valid for {0} days. Be sure to renew it before the expiry deadline if you have not received and offer.',
    articles: 'Articles',
    assigned: 'Assigned',
    association: 'Association',
    availability: 'Availability',
    availableHouses: 'Available homes',
    back: 'Back',
    backToEditApplication: 'Back to application',
    bankAccountNumber: 'Bank account number',
    bankAccountNumberInfo2: 'Must be you own personal account',
    bankAccountTypeInfo: 'International students must use a bank account with IBAN/SWIFT',
    basicInformation: 'Basic information',
    bath: 'Bath',
    bottomRentText: 'You can apply to several homes. We recommend at least 3.',
    byUsingLoginMethods: 'By using one of the logins above, you accept our',
    calendar: 'Calendar',
    campus: 'Campus',
    cancel: 'Cancel',
    cancelApplication: 'Cancel application',
    cancelContractTermination: 'Confirm cancellation of contract termination',
    cardOnly: 'Card Only',
    category: 'Kategori',
    changeEmailHelperText: 'Contact Samskipnaden if you need to change your email address.',
    checklist: 'Checklist',
    checklists: 'Checklists',
    children: 'Children',
    childrenText: 'Important to know if you have children in order to get the right benefits',
    chooseBankAccountType: 'Choose type of bank account',
    chooseCountry: 'Choose country',
    chooseMessageType: 'Choose message type',
    choosePrice: 'Choose price',
    chooseSize: 'Choose size',
    city: 'City',
    cleaningStaff: 'Cleaning staff',
    close: 'Close',
    codeFromSms: 'Code from SMS',
    comment: 'Comment',
    commentPlaceholder: 'Add your comment...',
    comments: 'Other comments',
    commentsText:
      'Add extra information relevant for you application. For example if you have friends you want to be close to. Write to us here!',
    commonAreaSelectLabel: 'Register errors at',
    complete: 'Complete',
    completed: 'Completed',
    confirm: 'Confirm',
    confirmFireRoutineVideoCheckbox: 'Yes, I have watched the video',
    confirmFireRoutineVideoText:
      "Hi! As a student it's important that you are familiar with our routines in case of a fire",
    confirmPhoneNumber: 'Confirm mobile number',
    connect: 'Connect',
    consent: 'Consent',
    consented: 'Consented at',
    consents: 'Consents',
    consentText:
      'Samskipnaden collects several of our services in one place to make life as a student easier. To do this we need your consent to share information between the different services and providers.',
    contact: 'Contact',
    contactInfo: 'Contact info',
    contactInformation: 'Contact information',
    contactInformationText: 'Fill your contact information details',
    contactUs: 'Contact us',
    continue: 'Continue',
    continueWithEmail: 'Continue with e-mail',
    contract: 'Contract',
    contractAcceptLockText: 'I accept that Samskipnaden can enter my house.',
    contractExpiresAt: 'Your contract expires at',
    contractFirstPossibleVacatingDate: 'By terminating the contract will expire on',
    contractId: 'Contract ID',
    contracts: 'Contracts',
    contractSettlementInfo: 'Final settlement information',
    contractTerminationText: 'Submit contract termination for',
    contractTerminationTitle: 'Submit contract termination',
    cookies: 'cookies',
    country: 'Country',
    create: 'Create',
    createEvent: 'Create event',
    createStudentUnion: 'Add student organization',
    createNewMessage: 'Create new message',
    createUser: 'Create user',
    daily: 'Daily',
    date: 'Date',
    dateOfBirth: 'Date of birth',
    deadline: 'Deadline',
    declineContract: 'Decline contract',
    deletingApplication: 'Deleting application...',
    deposit: 'Deposit',
    depositPayButton: 'Pay deposit',
    description: 'Description',
    descriptionPlaceholder: 'What is the inquiry about?',
    details: 'Details',
    documentation: 'Documentation',
    documentationText:
      ' Dersom du er førsteårsstudent og enda ikke har fått dokumentasjon på at du er kommet inn på studiet, kan du hoppe over punktet om dokumentasjon. Alle andre kan legge ved dokumentasjon på betalt studieavgift, studieprogresjon eller lignende.',
    download: 'Download',
    downloadInvoice: 'Download invoice',
    dueDate: 'Due date',
    editApplication: 'Edit application',
    editPeriod: 'Housing',
    editPeriodText: 'Fill in when you want housing.',
    email: 'Email address',
    errorGymAccountDetails:
      'We could unfortunately not connect your account details to a Kraft membership. Log in through Feide or verify that you use the same phone number on MyPage as with Kraft Sports Centre.',
    errorLogin: 'An error occurred when signing in!',
    errorRefreshPage: 'Something went wrong. Try refreshing the page.',
    errorSomethingWrong: 'Something went wrong',
    event: 'event',
    events: 'events',
    expired: 'Expired',
    expiredApplications: 'Expired applications',
    expiredDate: 'Expired date',
    expiredMemberships: 'Expired memberships',
    facilities: 'Facilities',
    fault: 'Fault',
    phone: 'Phone',
    fetchingPlaces: 'Fetching places...',
    filesWithErrors: 'Files with errors',
    findHousing: 'Find homes',
    firstName: 'First name',
    fitness: 'Fitness',
    FoodAndBeverageConsentPermissions1:
      'Store information about each purchase registered with your QR code',
    FoodAndBeverageConsentPermissions2: 'Create an account in the MUNU user database',
    FoodAndBeverageConsentPermissions3:
      "Creation of QR codes and participation in Samskipnaden's benefits program",
    FoodAndBeverageConsentPermissions4:
      'Use of purchase, benefit and activity information for further development of service and adapted offers',
    FoodAndBeverageConsentText:
      'To make your life as a student easier, Samskipnaden unifies many of our services on MyPage. To create better services, we collect and analyze information about use, purchases, coupon use. We also use collected data to provide offers tailored for you. When using our benefits program, an anonymised account in our cash register system MUNU is created.',
    FoodAndBeverageConsentTitle: 'Consent to:',
    forgotPassword: 'Forgot password?',
    formErrors: 'Form contains errors. Please see errors on individual fields.',
    foundNoEvents: 'Found no events',
    foundNoNews: 'Found no news',
    freeCoffee: 'You have received a free coffee!',
    freeCoffeeDescription:
      'Scan your QR code at the register next time you shop in one of our cafeterias, and you will receive a free coffee. After this, every tenth coffee is free when you scan them with your QR code. See my benefits.',
    friday: 'Friday',
    fromDate: 'From date',
    fromSemester: 'From semester',
    frontPage: 'Home',
    fullName: 'Full name',
    genderExplainer: 'Why do we ask for this? Explanation here.',
    generalError: 'Something went wrong. Please try again later.',
    generalErrorMessage: 'Something went wrong. Please try again later.',
    generalLoginErrorMessage: 'Wrong username or password',
    generalTermsOfUse: 'general terms of use.',
    getAccess: 'Få tilgang',
    getAccessLoading: 'Gir tilgang...',
    getStarted: 'Get started',
    giveConcent: 'Give consent',
    good: 'Good',
    goToApplication: 'Go to application',
    goToLegacyContract: 'Go to legacy housing portal',
    goToMyPage: 'Go to My Page',
    goToNext: 'Go to next',
    goToPasswordReset: 'Reset password',
    gymConsentPermissions1: 'Retrieve your account information from Kraft and iBooking',
    gymConsentPermissions2: 'Store and present visits, payments and membership information',
    gymConsentPermissions3:
      'Analyze training data from Kraft and iBooking for the development of better services and adapted offers on Samskipnaden.no.',
    gymConsentText:
      'To make your life as a student easier, Samskipnaden unifies many of our services on MyPage. By giving consent to "My training", you give permission to collect your membership information from Kraft Sportssenter and iBooking. We use collected data to present your membership info on MyPage, provide customized offers and improved our services.',
    gymConsentTitle: 'Consent to:',
    handicap: 'I need a house adapted to a person with disabilities.',
    handicapAdapted: 'Accessible',
    handicapText: 'Describe your needs here. Please do not disclose medical information.',
    health: 'Health',
    healthConsentText: ' ',
    healthConsentTitle: 'Consent to:',
    helpMe: 'Hjelp meg',
    hi: 'Hi',
    home: 'Home',
    housing: 'Housing',
    housingApplicationConsentText:
      'I confirm that all provided information is correct and I consent to the use of my personal information for the purpose of applying for housing.',
    housingArea: 'Area',
    housingConsentPermissions1:
      "Retrieval, display, and storage of the housing information that the logged-in user has registered in UniAlltid, Samskipnaden's former housing portal (studentbolig.samskipnaden.no), and/or on My Page. The housing information includes and is not limited to invoice information, living conditions, checklists, applications, information about children and cohabitants, status of study, and the like. The user also gives Samskipnaden the right to show and send inquiries (with and without attachments) to the housing service provided by Ørn Software AS, on My Page.",
    housingConsentPermissions2: '----',
    housingConsentPermissions3: '----',
    housingConsentText:
      'Henting, visning og lagring av boliginformasjonen som den innloggede bruker har registrert på UniAlltid, Samskipnadens tidligere boligportal (studentbolig.samskipnaden.no), og/eller på MinSide. Boliginformasjonen inkluderer og er ikke begrenset til fakturainformasjon, boforhold, sjekklister, søknader, informasjon om barn og samboer, studiestatus og lignende. Brukeren gir også Samskipnaden retten til å vise og sende henvendelser (med og uten vedlegg) til boligtjenesten levert av Ørn Software AS, på MinSide.',
    housingConsentTitle: 'Samtykke til:',
    housingLoginIntroText1:
      'Vi har slått sammen den gamle boligportalen med Min Side. For å administrere ditt boligforhold må du logge inn på Samskipnaden Min Side',
    housingLoginIntroText2a: 'Trykk her for å se et bilde',
    housingLoginIntroText2b:
      ' av Min Side og den gamle boligportalen hvis du er usikker på om du har vært logget inn før.',
    housingLoginPageText:
      'We have combined the old housing portal with My Page. You must first log in with your My Page user, or register as a new user to access the housing services',
    housingLoginPageTitle: 'Important information about housing services',
    housingLoginSignup: "If you don't have a user, you can create one here.",
    housingLoginSignupQ1: 'Why do I have to do this?',
    housingLoginSignupQ1Answer:
      'By creating and logging in, you connect Min side and Samskipnaden housing portal. It is two separate systems that must be connected so that you can use our housing services via Min Side.',
    housingLoginSignupQ2: 'For current/former residents and former applicants',
    housingLoginSignupQ2Answer:
      'If you are a current or former resident, you can log in with your existing user. If you do not have a user, you can create one here.',
    housingLoginText:
      'We have merged the old housing portal with Samskipnaden Min Side. You must log in with your old housing user to get access.',
    housingLoginTitle: 'Important information Samskipnaden housing services',
    housingMoveOutBookingButtonLabel: 'Open booking page',
    housingMoveOutBookingText:
      'We are expanding our services to make the move-out process better for you! Book a move-out check before moving out. We will go through the housing together with you, if the cleaning is approved you can hand in the key straight away!',
    housingMoveOutBookingTitle: 'Book a move-out check',
    housingNameFormTitle: 'We also need your name to connect the system to your profile.',
    housingPreferredRelocationHelperText:
      'Feel free to add the date you plan to move out. This is not binding and does not change the expiry of the contract or the obligation to pay rent for the whole notice period.',
    housingRegisterUserConsent: 'By continuing you accept our',
    housingShowHouses: 'Show homes',
    housingSignupLogin: 'If you are already staying with us, you can log in here.',
    housingSignupText:
      'We have merged the old housing portal with Samskipnaden Min Side. You must register a housing user to get access.',
    housingSignupTitle: 'Important information Samskipnaden housing services',
    housingTypes: 'options',
    housingWishes: 'Housing wishes',
    housingWishLimitWarning:
      'You can only have a maxmium of {0} homes in your application list. Please remove minimun of {1} homes.',
    ibanSwift: 'Bank with IBAN/SWIFT',
    inbox: 'Inbox',
    invalid: 'Invalid',
    invoice: 'Invoice',
    invoiceDate: 'Invoice date',
    invoiceNumber: 'Invoice number',
    invoicePayButton: 'Pay invoice',
    invoicePayButtonLoading: 'Redirecting to payment site',
    invoicePaymentAbortedDescription: 'Payment was canceled.',
    invoicePaymentAbortedTitle: 'Payment cancelled',
    invoicePaymentErrorDescription: 'An error occurred while paying. Please try again.',
    invoicePaymentErrorTitle: 'Payment failed',
    invoicePaymentSuccessDescription:
      'Your payment is registered and a receipt will be sent to your e-mail address when the payment has been processed.',
    invoicePaymentSuccessTitle: 'Payment of invoice',
    invoices: 'Invoices',
    keyInfo: 'Key info',
    kinderGartenHeadline: 'Did you know we have kindergartens?',
    kinderGartenText:
      "If you are a student with children, there's many many advantages with choosing a student kindergarten.",
    kitchen: 'Kitchen',
    label: 'Label',
    language: 'Language',
    lastName: 'Last name',
    laundry: 'Laundry',
    legacyPortalBannerLink: 'Use the old portal for more features',
    legacyPortalBannerText: 'Our new portal is in beta.',
    livingPeriod: 'Living period',
    livingPeriodText: 'Fill in your living period',
    loading: 'Loading...',
    loggingIn: 'Logging in...',
    login: 'Log in',
    logIn: 'Log in',
    logInAndReceivePerks1: 'Log in and receive perks',
    logInAndReceivePerks2:
      'As a member gain access to offers, loyalty cards and your proof of membership completely free.',
    logInWithFeide: 'Log in with Feide',
    logInWithGoogle: 'Log in with Google',
    logout: 'Log out',
    loyaltyCardCoffee:
      'The offer applies to black coffee in our cafees and is automatically registered when you scan your code at checkout. The offer does not apply to coffee at GRUT.',
    loyaltyCardGRUTkaffekort:
      'The offer applies to single or double coffee (latte, frappe etc.) and not current or regular (dagens) coffee / tea. The purchase is automatically registered when you scan your code at checkout. The offer does not apply to outlets other than GRUT coffee shops.',
    loyaltyCardGRUTkaffekortdagens:
      'The offer applies to the purchase of regular (dagens) coffee / tea, not espresso coffee. The purchase is automatically registered when you scan your code at checkout. The offer does not apply to outlets other than GRUT coffee shops.',
    loyaltyCardKaffekort:
      'The offer applies to black coffee in our cafees and is automatically registered when you scan your code at checkout. The offer does not apply to coffee at GRUT.',
    man: 'Man',
    marketingConsentText: 'We can send you relevant and personal offers on email.',
    marketingConsentTitle: 'Consent to:',
    maxApplicationsWarning: 'You can have maximum {0} applications at the time.',
    memberships: 'Memberships',
    menu: 'Menu',
    message: 'Message',
    messageAcceptLockText:
      ' I agree that operating personnel can unlock and enter the housing to fix errors even if I am not present.',
    messageAcceptLockTextCont:
      'Our operating personnel will always try to get in contact with you before correcting errors. With consent, errors can be corrected more quickly. Error correction is normally performed within ordinary working hours.',
    messages: 'Messages',
    messageType: 'Type of message',
    mobilePhoneNumber: 'Mobile number',
    monday: 'Monday',
    month: 'Month',
    monthly: 'Monthly',
    moreFromArticles: 'More from Samskipnaden',
    moreFromHousing: 'More from housing',
    moreFromUnions: 'More from unions',
    moreInfoFeide: 'More information about Feide',
    morePlacesToStay: 'More places to stay',
    moveInDate: 'Move in date',
    mustAccept: 'You must accept the terms and conditions',
    mustBeAfterDate: 'To date must be after from date',
    mustBeAfterSemester: 'Semester start must be before semester end',
    mustBeFuture: 'Date must be in the future',
    mustBeNumber: 'Must be a number',
    myConsents: 'Consents',
    myConsentsText: 'See and change my consents',
    myConsentsUrl: '/en/minside/samtykker',
    myFavourites: 'Favourites',
    myFavouritesText: 'Your campus and favorite eateries',
    myFavouritesUrl: '/en/minside/favoritter',
    myGym: 'Fitness',
    myGymText: 'My membership, payments and visits',
    myGymUrl: '/en/minside/trening',
    myHealthUrl: '/en/minside/helse',
    myHomeUrl: '/en/minside',
    myHousing: 'Housing',
    myHousingUrl: '/en/minside/bolig',
    myInboxUrl: '/en/minside/innboks',
    myPage: 'My page',
    myPageForYou: 'Offers for you',
    myPageHousingApplyLink: 'Find student housing',
    myPageHousingApplyText: 'To use this service, you must register as an applicant.',
    myPageHousingApplyTitle: 'Apply for student housing',
    myPageHousingForgotPasswordItem1: 'Press “Reset password” below',
    myPageHousingForgotPasswordItem2A:
      'In the new window, type in the email you have used in the old housing portal',
    myPageHousingForgotPasswordItem2B: ' and click reset',
    myPageHousingForgotPasswordItem3:
      'You will receive an email with a link. Click the link and make a new password.',
    myPageHousingForgotPasswordItem4: 'Return to this page and type in your new password.',
    myPageHousingForgotPasswordTitle:
      'We apologize for the inconvenience; the password must be reset in the old housing portal.',
    myPageHousingGetAccessText:
      'Kunne bo i en av Samskipandens flotte boliger. Alle kontrakter, fakturaer, inn og utflyttingslister på et sted. Sende melding til vaktmester hvis det er noe feil eller mangler',
    myPageHousingGetAccessTitle: 'Everything about your student housing in one app',
    myPageHousingGetStartedText:
      'Have you applied for or lived in student housing through us before?',
    myPageHousingGetStartedTitle:
      'We have merged our housing systems and need more information from you.',
    myPageHousingListItem1: 'Apply for housing and manage your application',
    myPageHousingListItem2: 'Access to all contracts, invoices, and messages',
    myPageHousingListItem3: 'Contact our housing staff',
    myPageHousingLoginHelperText:
      'NB! You might have used a different email in the old housing portal.',
    myPageHousingLoginTitle: 'Log in with your email and password from the old housing portal.',
    myPageHousingUserExistsButton: 'Gå til pålogging',
    myPageHousingUserExistsText1A:
      'We have merged the old Housing portal with My Page. Your e-mail ',
    myPageHousingUserExistsText1B: ' already exists in the old housing portal.',
    myPageHousingUserExistsText2:
      'You need to login with your existing user for access to the housing services on My Page.',
    myPageHousingUserExistsText3:
      "If you're not sure what we're talking about, click here to contact us so that we can help.",
    myPageHousingUserExistsTitle: 'Your email is already registered!',
    myPerks: 'Perks',
    myPerksText: 'Your perks and loyalty card',
    myPerksUrl: '/en/minside/fordeler',
    myPrepaid: 'Prepaid cards',
    myPrepaidText: 'View and buy prepaid cards',
    myPrepaidUrl: '/en/minside/klippekort',
    myProfile: 'My profile',
    myProfileText: 'Change my contact info and association',
    myProfileUrl: '/en/minside/profil',
    myPurchases: 'Purchases',
    myPurchasesText: 'Your perks and discounts',
    myPurchasesUrl: '/en/minside/kjop',
    newMessage: 'New message',
    news: 'News',
    newUser: 'New user',
    nextOfKin: 'next of kin',
    nextOfKinText: 'Fill in contact information of your next of kin',
    no: 'No',
    noApplications: 'No applications',
    noApplicationsText: 'Do you need a place to stay? We have many available homes.',
    noComments: 'No comments',
    noContracts: 'No contracts',
    noFavourites1: 'No favourites',
    noFavourites2: 'We found no saved favourites.',
    noHits: 'No hits',
    noHousesWarning: 'You have no homes in your application list',
    noLoyaltyCard: 'No loyalty card',
    noMembership: 'No membership',
    none: 'None',
    nonStudent: 'Non-student',
    noPurchases: 'No purchases',
    norwegianBankAccount: 'Norwegian bank account',
    notFound: 'Page not found',
    notFoundDescription:
      'The page you are looking for does not exist. Please check the URL and try again.',
    notFoundGoHome: 'Go to home page',
    notifications: 'Notifications',
    notNow: 'Not now',
    notRegisteredUser: 'Not a registered user?',
    notRelevant: 'Not relevant',
    notValidEmail: 'Not a valid email address',
    noUnpaidInvoice: 'No unpaid invoices',
    numberOfKeys: 'Number of keys',
    numberOfRooms: 'Number of rooms',
    of: 'of',
    oftenUsedPages: 'Often used pages',
    onboardingCodeError:
      'Unfortunately we could not send the verification code. Please check that the given phone number is correct.',
    onboardingConsentAlert:
      'If we do not get your consent, we will only create an account on My Page, but without access to our services or the content on My Page. You can always delete your My Page account under Profile.',
    onboardingConsentIntro:
      "Become a member of Samskipnaden's loyalty program and get access to our best offers.",
    onboardingConsentLoyaltyProgram: "Yes, I want to join Samskipnaden's perks program.",
    onboardingConsentMarketing:
      'Yes, I want to receive offers and news from Samskipnaden by e-mail.',
    onboardingConsentReadPrivacy: 'Read more about consent.',
    onboardingConsentText:
      'To create better services, we collect and analyze information about use, purchases, and coupon usage. We also use collected data to facilitate offers for you. By using our loyalty program, an anonymized account is created in our cash register system.',
    onboardingConsentTraining:
      'Yes, I want to link my Kraft Sports Centre account to Samskipnaden’s MyPage.',
    onboardingGoBack: 'Go back',
    onboardingNotRecievedCode: 'Did not get a code?',
    onboardingTellUsAbout: 'Tell us about yourself',
    onboardingWelcome: 'Welcome!',
    onboardingWelcomeIntro:
      'On My Page you’ll find the QR-code for all our cafeterias and coffee shops, and your offers as a member of our benefit member. My Page content:',
    onboardingWelcomeText1: 'Get exclusive perks and offers for you as a member of My Page',
    onboardingWelcomeText2:
      'Get an overview of purchases and transactions in our canteens and coffee bars',
    onboardingWelcomeText3:
      'Buy student - cards for better prices in our cafeterias and coffee shops',
    onboardingWelcomeText4: 'Manage your student housing and gym membership in one place',
    onboardingWelcomeText5: 'Use your QR code in all our cafeterias and coffee shops',
    onboardingWelcomeText6:
      'With an account on My Page, you get access to all of our services in one place. Link your Feide account, with your own login, and get student discounts right away.',
    openQrCode: 'Show my QR code',
    or: 'or',
    other: 'Other',
    otherInformation: 'Other information',
    ourServices: 'Our services',
    own: 'Own',
    paid: 'Paid',
    paidOut: 'Paid out',
    partner: 'Partner',
    partnerText: 'Fill in if you plan to live with your partner',
    password: 'Password',
    pastApplications: 'Past applications',
    pastContracts: 'Past contracts',
    payments: 'Payments',
    perks: 'Perks',
    perMonth: 'pr/mnth',
    pets: 'Pets',
    petsText:
      'Pets are only allowed in certain homes. Renters need to send a written application to apply for keeping pets. Contact us for more information.',
    pick: 'Choose',
    place: 'Place',
    placeOfStudy: 'Place of study',
    plannedVacatingDate: 'Planned date for vacating',
    pleaseWait: 'Please wait...',
    plural: 's',
    postalCode: 'Postal Code',
    preferences: 'Preferences',
    prepaid: 'Prepaid cards',
    price: 'Price',
    privacy: 'Privacy',
    privacyPolicy: 'privacy policy',
    problem: 'Problem',
    profile: 'Profile',
    profileHousingAccountDescription: 'Used for e.g refund of deposit and rent',
    qrCodeText: 'Scan this code at all eateries to automatically get the best prices and perks.',
    quickAdd: 'Quick add',
    readMoreAboutGymOffer: 'Read more about our gym membership offer.',
    readMoreConsent: 'Read more about consent.',
    readMorePrivacy: 'Read about privacy here.',
    recommendedForStudents: "Recommended if you're a student!",
    remove: 'Remove',
    removeApplicationText:
      'Are you sure you want to remove your application? You will not be able to undo this action.',
    renewApplication: 'Renew application',
    rent: 'Rent',
    rentIncludes: 'Included in the rent',
    requestRoomSwapText: 'You can submit a request to swap rooms for this contract',
    requestRwapRoomTitle: 'Request room swap',
    required: 'Required',
    reset: 'Reset',
    room: 'Room',
    roomSwap: 'Swap room request',
    roomSwapAsap: 'I want to swap as soon as possible',
    roomSwapSubmit: 'Submit room swap request',
    roomSwapText: 'Request a room swap for',
    roomSwapTitle: 'Request room swap',
    saturday: 'Saturday',
    save: 'Save',
    saveAndClose: 'Save and close',
    saveAndNext: 'Save and go to next',
    saveBankAccount: 'Save bank account',
    savePreferences: 'Save preferences',
    saveProfile: 'Save profile',
    saving: 'Saving...',
    search: 'Search',
    searchTitle: 'What are you looking for?',
    seeAllArticles: 'See all articles',
    seeAllKindergartens: 'See all kindergartens',
    seeAllNews: 'See all news',
    seeAllUnions: 'See all',
    seeMoreHouses: 'See more homes',
    seeMoreServices: 'See more of our offers and services.',
    seeMyPerks: 'See my perks',
    selectGender: 'Select gender',
    sendApplication: 'Send application',
    service: 'Service',
    shared: 'Shared',
    shortTermRental: 'Short term rental',
    show: 'Show',
    showApplicationList: 'Show application list',
    showingArticleNum: 'Showing {0} of {1} articles',
    showingEventsNum: 'Showing {0} of {1} events',
    showingSearchResultsNum: 'Showing {0} hits for {1}',
    showingStudentUnionNum: 'Showing {0} of {1} student unions',
    showLess: 'Show less',
    showMore: 'Show more',
    showOlder: 'Show older',
    signContractAbortedDescription:
      'Signing of contract was canceled. You can however still sign the contract until the signing deadline, which you will find under contract details',
    signContractAbortedTitle: 'Signing canceled',
    signContractDigitallyButton: 'Sign with BankID',
    signContractErrorDescription: 'An error occurred when signing the contract. Please try again.',
    signContractErrorTitle: 'Signing failed',
    signContractSuccessDescription: 'Signing completed, thanks for your signature.',
    signContractSuccessTitle: 'Signing completed',
    signContractText:
      'You can sign contract digitally with BankID or upload a signed version of the contract.',
    signContractTitle: 'Contract signing',
    signedContract: 'Signed contract',
    size: 'Size',
    special: "Today's special",
    specialNeeds: 'Special needs',
    specialNeedsText:
      'For å bli prioritert for bolig tilpasset personer nedsatt funksjonsevne, oppgi dette her. (Tekst om dokumentasjon må inn her.)',
    startChecklist: 'Start checklist',
    startRoomSwap: 'Start request',
    startTerminateContract: 'Start termination of contract',
    startUsingMyPage: 'Go to MyPage',
    status: 'Status',
    storageRoom: 'Storage room (Tromsø)',
    studentHouse: 'Student house',
    studentHousing: 'Student Housing',
    studyInformation: 'Study information',
    studyPeriod: 'Study period',
    submitTermination: 'Submit termination',
    submitting: 'Submitting...',
    suitableFor: 'Suitable for',
    summary: 'Summary',
    summaryText: 'Check and make sure everything is entered correctly.',
    sunday: 'Sunday',
    tasks: 'tasks',
    tenancy: 'Tenancy',
    terminateContractText:
      'You can submit a request to terminate this contract according to the terms of the contract.',
    terminateContractTitle: 'Termination',
    termination: 'Termination',
    thisWeek: 'This week',
    thursday: 'Thursday',
    title: 'Title',
    titlePlaceholder: 'Write title here',
    toDate: 'To date',
    tooHighNumber: 'Tallet er for høyt',
    tooLowNumber: 'Number is too low',
    topic: 'Topic',
    toSemester: 'To semester',
    training: 'Fitness',
    trainingPartner: 'Training partner',
    tuesday: 'Tuesday',
    unions: 'Unions',
    unknownStatus: 'Unknown status',
    unmanned: 'Unmanned',
    unpaid: 'Unpaid',
    unSignedContract: 'Unsigned contract',
    updated: 'Updated',
    uploadSignedContractText: 'Upload signed contract',
    usefulLinks: 'Useful links',
    validationBankAccount: 'Must be 11 characters with no spaces or punctuation',
    verifyAlertDescription1:
      'Verify that you are a student for the best prices, offers and access to all services.',
    verifyAlertDescription2: 'Verify easily by logging in using your Feide account',
    verifyAlertTitle: 'Are you a student?',
    viewApplication: 'View application',
    viewList: 'View list',
    viewMap: 'View on map',
    visits: 'Visits',
    website: 'Website',
    wednesday: 'Wednesday',
    weekly: 'Weekly',
    weFoundNoLoyaltyCard: 'We found no loyalty card',
    weFoundNoPurchases: 'We found no searches',
    welcome: 'Welcome',
    withdrawContractTerminationButton: 'Withdraw termination',
    withdrawContractTerminationText: 'You can withdraw your termination request for this contract.',
    withdrawContractTerminationTitle: 'Withdraw termination',
    woman: 'Woman',
    writeMessageHere: 'Write message here',
    yearly: 'Yearly',
    yearOfBirth: 'Year of birth',
    yes: 'Yes',
    yourCode: 'Your code',
    yourEmail: 'Your email address',
    yourFirstName: 'Your first name',
    yourLastName: 'Your last name',
    yourName: 'Your name',
    yourPhoneNumber: 'Mobile number',
    faculty: 'Faculty',
    copied: 'Copied',
    copy: 'Copy',
    generateNewPassword: 'Generate new password',
    noAvailiabeNetworks: 'No available networks',
    wirelessNetworks: 'Wireless networks',
    yourNetworks: 'Your networks',
    wifiText1:
      'This is your personal password for the wireless network in your housing. The name of the network you should use is Samskipnaden.',
    wifiText2: 'You can share the password with your guests and visitors.',
    wifiText3: 'ou can also generate a new password yourself in the interface above.',
    wifiText4: 'You can find more information about the internet in our student housing here',
    wifiText5:
      'UiT delivers and operates the internet in our housing. They also help with user support and troubleshooting.',
    wifiText6: 'Here you will find useful user guides for internet',
    wifiText7: 'We recommend reading the user guides before contacting us or reporting an error.',
    wifiText8: 'Here you can contact or report errors to Orakelet ',
    wifiChangeDisclaimer:
      'After you have changed the password, we recommend that you disconnect from and forget the “Samskipnaden” network before you log in again. Note that the new password must be re-entered on all your devices.',
    roomNumber: 'Room number',
    studentCounsellingCentre: 'Student Counselling Centre',
    wifi: 'Wireless networks',
  },
}

const norwegianfy = (obj): StringMaps => ({
  ...obj,
  'nb-NO': obj.no,
})

const l10n = new LocalizedStrings(norwegianfy(strings))

export default l10n
