import { ComponentStyleConfig } from '@chakra-ui/react'
import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys
)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    fontWeight: 'medium',
  },
})
const variantOutline = definePartsStyle({
  // define the part you're going to style
  field: {
    borderColor: 'gray.400',
    _hover: {
      borderColor: 'black',
      _disabled: {
        borderColor: 'gray.400',
      },
    },
    _focusVisible: {
      borderColor: 'orange.400',
      boxShadow: '0 0 0 1px var(--chakra-colors-orange-400)',
    },
  },
})

const variants = {
  outline: variantOutline,
}

const inputTheme = defineMultiStyleConfig({ baseStyle, variants })

export default inputTheme
