export const colors = {
  orange: {
    50: '#FFF6E9', //'#fff7ed',
    100: '#FFECD3', //'#ffedd5',
    200: '#FEDFBF', //'#fed7aa',
    300: '#FDC689', //'#fdba74',
    400: '#FAA73F', //'#fb923c',
    500: '#F7941D', //'#f97316',
    600: '#D9791F', //'#ea580c',
    700: '#BC5F21', //'#c2410c',
    800: '#9E4423', //'#9a3412',
    900: '#812A25', //'#7c2d12',
    // 1000:'#630F27'
  },
  red: {
    50: '#fef2f2', //'#FFE5DE',
    100: '#fee2e2', //'#FCCFC2',
    200: '#fecaca', //'#F7A391',
    300: '#fca5a5', //'#F2665E',
    400: '#f87171', //'#EF4747',
    500: '#ef4444', //'#D33C41',
    600: '#dc2626', //'#B7313A',
    700: '#b91c1c', //'#9B2534',
    800: '#991b1b', //'#7F1A2D',
    900: '#7f1d1d', //'#630F27',
  },
  brown: {
    50: '#F6EEE9',
    100: '#F2E4D5',
    200: '#E9CEB5',
    300: '#E2B990',
    400: '#D9A87D',
    500: '#BF815B',
    600: '#A86840',
    700: '#8A502B',
    800: '#6B3A1B',
    900: '#4A2610',
  },
  blue: {
    50: '#f0f9ff', //'#F4FBFE',
    100: '#e0f2fe', //'#DFF3FD',
    200: '#bae6fd', //'#C2DFF5',
    300: '#7dd3fc', //'#ACD6F2',
    400: '#38bdf8', //'#88C8EE',
    500: '#0ea5e9', //'#70A8CF',
    600: '#0284c7', //'#5889B1',
    700: '#0369a1', //'#3F6992',
    800: '#075985', //'#274A74',
    900: '#0c4a6e', //'#0F2A55',
  },
  green: {
    50: '#f0fdf4', //'#EAF3E7',
    100: '#dcfce7', //'#DDEED8',
    200: '#bbf7d0', //'#C2E0B5',
    300: '#86efac', //'#9ED193',
    400: '#4ade80', //'#87C874',
    500: '#22c55e', //'#6FAE65',
    600: '#16a34a', //'#589556',
    700: '#15803d', //'#407B46',
    800: '#166534', //'#296237',
    900: '#14532d', //'#114828',
  },
  gray: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#e5e5e5',
    300: '#d4d4d4',
    400: '#a3a3a3',
    500: '#737373',
    600: '#525252',
    700: '#404040',
    800: '#262626',
    900: '#171717',
  },
  dark: {
    50: '#f5f5f5',
    100: '#d4d4d4',
    200: '#0c0c0c',
    300: '#0e0e0e',
    400: '#101010',
    500: '#0c0c0c',
    600: '#262626',
    700: '#404040',
    800: '#1c1c1c',
    900: '#202020',
  },
  white: '#FFF',
  black: '#0C0C0C',
}
