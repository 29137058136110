import React from 'react'
import NProgress from 'nprogress'
import Router from 'next/router'
import { Global, css } from '@emotion/react'

class ProgressBar extends React.Component<Props> {
  static defaultProps = {
    color: '#29D',
    startPosition: 0.3,
    stopDelayMs: 200,
    height: 3,
  }

  timer = null

  routeChangeStart = () => {
    NProgress.set(this.props.startPosition)
    NProgress.start()
  }

  routeChangeEnd = () => {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      NProgress.done(true)
    }, this.props.stopDelayMs)
  }

  render() {
    const { color, height } = this.props

    return (
      <Global
        styles={css`
          #nprogress {
            pointer-events: none;
          }
          #nprogress .bar {
            background: ${color};
            position: fixed;
            z-index: 1500;
            top: 0;
            left: 0;
            width: 100%;
            height: ${height}px;
          }
          #nprogress .peg {
            display: block;
            position: absolute;
            right: 0px;
            width: 100px;
            height: 100%;
            opacity: 1;
            -webkit-transform: rotate(3deg) translate(0px, -4px);
            -ms-transform: rotate(3deg) translate(0px, -4px);
            transform: rotate(3deg) translate(0px, -4px);
          }
          .nprogress-custom-parent {
            overflow: hidden;
            position: relative;
          }
          .nprogress-custom-parent #nprogress .bar {
            position: absolute;
          }
        `}
      />
    )
  }

  componentDidMount() {
    const { options } = this.props

    if (options) {
      NProgress.configure(options)
    }

    Router.events.on('routeChangeStart', this.routeChangeStart)
    Router.events.on('routeChangeComplete', this.routeChangeEnd)
    Router.events.on('routeChangeError', this.routeChangeEnd)
  }
}

type Props = {
  color: string
  startPosition: number
  stopDelayMs: number
  options: object
  height: string
}

export default ProgressBar
