import { ComponentStyleConfig } from '@chakra-ui/react'

const Select: ComponentStyleConfig = {
  // style object for base or default style
  // baseStyle: {
  //   sizes: {
  //     md: {
  //       field: {
  //         borderRadius: '4px',
  //       },
  //     },
  //   },
  // },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    md: {
      field: {
        borderRadius: '4px',
      },
    },
  },
  // styles for different visual variants ("outline", "solid")
  variants: {
    outline: {
      field: {
        fontWeight: 'medium',
        borderColor: 'gray.400',
        _hover: {
          borderColor: 'black',
          _disabled: {
            borderColor: 'gray.400',
          },
        },
        _focusVisible: {
          borderColor: 'orange.400',
          boxShadow: '0 0 0 1px var(--chakra-colors-orange-400)',
        },
      },
    },
  },
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    size: '',
    variant: 'outline',
    colorScheme: '',
  },
}

export default Select
