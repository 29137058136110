import { ComponentStyleConfig } from '@chakra-ui/react'
import type { StyleFunctionProps } from '@chakra-ui/styled-system'

const Button: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {
    fontWeight: 'medium',
    borderRadius: '4px',
  },
  variants: {
    solid: (props: StyleFunctionProps) => ({
      color: props.colorScheme === 'dark' ? 'white' : 'black',
    }),
    pill: {
      borderRadius: 'full',
      background: 'gray.200',
      backgroundColor: 'gray.200',
      border: '2px solid',
      borderColor: 'gray.200',
      _hover: {
        background: 'gray.300',
        backgroundColor: 'gray.300',
        border: '2px solid',
        borderColor: 'gray.300',
        _disabled: {
          background: 'gray.200',
          backgroundColor: 'gray.200',
          border: '2px solid',
          borderColor: 'gray.200',
        },
      },
      _active: {
        background: 'gray.300',
        backgroundColor: 'gray.300',
        border: '2px solid',
        borderColor: 'gray.400',
      },
    },
    chunky: {
      borderRadius: '10px',
      bgColor: 'black',
      color: 'white',
      _hover: { bgColor: 'gray.800', _disabled: { bgColor: 'gray.800' } },
    },
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    lg: {
      paddingEnd: 5,
      paddingStart: 5,
    },
  },
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    size: 'sm',
    variant: 'solid',
    colorScheme: 'orange',
  },
}

export default Button
