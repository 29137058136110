const styles = {
  global: {
    body: {
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      bg: 'white',
      color: 'black',
    },
  },
}

export default styles
