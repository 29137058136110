import { ComponentStyleConfig } from '@chakra-ui/react'

const Checkbox: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {
    label: {
      fontWeight: 'medium',
    },
    control: {
      borderColor: 'gray.400',
      _focusVisible: {
        ring: 2,
        ringColor: 'orange.400',
      },
    },
  },
  // styles for different sizes ("sm", "md", "lg")
  // sizes: {
  // },
  // // styles for different visual variants ("outline", "solid")
  // variants: {

  // },
  // // default values for 'size', 'variant' and 'colorScheme'
  // defaultProps: {
  //   size: '',
  //   variant: 'outline',
  //   colorScheme: '',
  // },
}

export default Checkbox
