export const fonts = {
  body: 'neue-haas-grotesk-text, sans-serif',
  heading: 'neue-haas-grotesk-display, sans-serif',
}

export const fontSizes = {
  xs: '0.75rem', // 12
  sm: '0.875rem', // 14
  md: '1rem', // 16
  lg: '1.125rem', // 18
  xl: '1.25rem', // 20
  '2xl': '1.5rem', // 24
  '3xl': '1.875rem', // 30
  '4xl': '2.25rem', // 36
  '5xl': '3rem', // 48
  '6xl': '3.75rem', // 60
  '7xl': '4.5rem', // 72
  '8xl': '6rem', // 96
  '9xl': '8rem', // 12
}
// Old scale
// '3xs': '0.625rem', // 10px
// '2xs': '0.75rem', // 12px
// xs: '0.875rem', // 14px
// sm: '1rem', // 16px
// md: '1.125rem', // 18px
// lg: '1.25rem', // 20px
// l: '1.375rem', // 22px
// xl: '1.5rem', // 24px
// '2xl': '1.75rem', // 28px
// '3xl': '2.125rem', // 34px
// '4xl': '3.5rem', // 56px
// '5xl': '4rem', // 64px
// '6xl': '4.5rem', // 72px
// '7xl': '4.5rem', // 72pxs

export const lineHeights = {
  normal: 'normal',
  none: 1,
  shorter: 1.25,
  short: 1.375,
  base: 1.5,
  tall: 1.625,
  taller: '2',
  '3': '.75rem',
  '4': '1rem',
  '5': '1.25rem',
  '6': '1.5rem',
  '7': '1.75rem',
  '8': '2rem',
  '9': '2.25rem',
  '10': '2.5rem',
}

export const fontWeights = {
  normal: 400,
  'normal-display': 500,
  medium: 500,
  'medium-display': 600,
  bold: 700,
  'bold-display': 700,
}
