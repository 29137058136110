import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'
// Global style overrides
import styles from './styles'

// Foundational style overrides
import { fonts, fontSizes, lineHeights, fontWeights } from './foundations/typography'
import { colors } from './foundations/colors'
import { space, sizes } from './foundations/spacing'

// Component style overrides
import Prose from './components/prose'
import Button from './components/button'
import Input from './components/input'
import Select from './components/select'
import Checkbox from './components/checkbox'
import Radio from './components/radio'
import Heading from './components/heading'

const overrides = {
  styles,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  colors,
  space,
  sizes,
  components: {
    Prose,
    Heading,
    Button,
    Input,
    Select,
    Checkbox,
    Radio,
    Divider: {
      variants: {
        comment: {
          borderRadius: 'full',
          position: 'relative',
          left: '35px',
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: 'gray.100',
          width: 'fit-content',
        },
      },
    },
    Paper: {
      baseStyle: {
        display: 'flex',
        alignItems: 'center',
        background: '#FFF',
        borderWidth: 1,
        borderRadius: 'xl',
        borderColor: 'gray.200',
        // boxShadow: 'sm',
        py: 5,
        px: 5,
        _hover: {
          // boxShadow: 'base',
        },
      },
      variants: {
        flat: {
          boxShadow: 'none',
        },
        highlight: {
          borderColor: 'orange.100',
          backgroundColor: 'orange.50',
          boxShadow: 'sm',
        },
        info: {
          borderColor: 'blue.200',
          backgroundColor: 'blue.100',
          // boxShadow: 'none',
          // color: 'blue.900',
        },
        success: {
          borderColor: 'green.200',
          backgroundColor: 'green.100',
          // boxShadow: 'none',
          // color: 'green.900',
        },
        warning: {
          borderColor: 'orange.100',
          backgroundColor: 'orange.50',
          // boxShadow: 'none',
          // color: 'orange.900',
        },
      },
      // The default variant value
      // defaultProps: {
      //   variant: 'smooth',
      // },
    },
  },
  // icons: {
  //   ...customIcons,
  // },
}

export default extendTheme(withDefaultColorScheme({ colorScheme: 'orange' }), overrides)
