import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { ChakraProvider } from '@chakra-ui/react'
import { GoogleAnalytics } from '@next/third-parties/google'
import CookieBar from 'components/CookieBar'
import { GraphQLClient } from 'graphql-request'
import l10n from 'l10n'
import { AppProps } from 'next/app'
import Router, { useRouter } from 'next/router'
import { useState } from 'react'
import { SWRConfig } from 'swr'
import ProgressBar from '../components/ProgressBar'
import theme from '../utils/theme/index'
import './style.css'

const API = process.env.NEXT_PUBLIC_APP_NASDB_URL
const GOOGLE_TAG_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_ID

const onRedirectCallback = (appState) => {
  // console.log(appState)
  Router.replace(appState?.returnTo || '/')
}

const SWR = ({ children }: { children: any }) => {
  const { getIdTokenClaims } = useAuth0()
  const [token, setToken] = useState(null)

  getIdTokenClaims().then((claims) => setToken(claims))

  if (!token) return children

  const graphQLClient = new GraphQLClient(API, {
    headers: {
      Authorization: token ? `Bearer ${token.__raw}` : null,
    },
  })

  return (
    // @ts-ignore
    <SWRConfig
      value={{
        // refreshInterval: 10000,
        // errorRetryInterval: 15000,
        revalidateOnFocus: false,
        fetcher: (query, ...args) => graphQLClient.request(query, ...args),
      }}
    >
      {children}
    </SWRConfig>
  )
}

//@ts-ignore
function App({ Component, pageProps, err }: AppProps) {
  const router = useRouter()
  l10n.setLanguage(router.locale)

  return (
    <Auth0Provider
      domain={process.env.NEXT_PUBLIC_APP_AUTH0_DOMAIN}
      clientId={process.env.NEXT_PUBLIC_APP_AUTH0_CLIENT_ID}
      redirectUri={
        typeof window !== 'undefined'
          ? window.location.origin
          : process.env.NEXT_PUBLIC_APP_AUTH0_REDIRECT_URI
      }
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      // cacheLocation="localstorage"
      // cacheLocation={typeof window !== 'undefined' ? 'localstorage' : 'memory'}
    >
      <ChakraProvider theme={theme} resetCSS>
        <ProgressBar
          color="#000"
          startPosition={0.4}
          stopDelayMs={100}
          height="2"
          options={{ showSpinner: false, trickle: true, trickleSpeed: 400 }}
        />
        <SWR>
          <CookieBar />
          <Component {...pageProps} err={err} />
          <GoogleAnalytics gaId={GOOGLE_TAG_ID} />
        </SWR>
      </ChakraProvider>
    </Auth0Provider>
  )
}

export default App
